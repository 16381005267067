import React from "react"
import Layout from '@components/layout/layout.jsx'
import DocumentHeadMeta from '@components/utility/SEO.jsx'
import { Link } from "gatsby"

//Components


// markup
const errorPage = ({ data }) => {

  return (
    <Layout addedClass="page--error">
      <DocumentHeadMeta title='500 Error | ALL IN&reg; for Lupus Nephritis' description='' robots="noindex" canonicalURL='https://www.allinforlupusnephritis.com/500' />
      <section className="bg-white">
        <div className="container container--narrow">
            <h1>Something went wrong</h1>
            <p><strong>Sorry! We're experiencing technical difficulties right now. Please try again in a few&nbsp;hours.</strong></p>
            <Link to="/" className="cta--body cta--purple">Home</Link>
        </div>
      </section>    
    </Layout>
  )
}

export default errorPage
